// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/ops/andymoresi/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-js": () => import("/home/ops/andymoresi/src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-9-to-5-js": () => import("/home/ops/andymoresi/src/pages/9-to-5.js" /* webpackChunkName: "component---src-pages-9-to-5-js" */),
  "component---src-pages-dear-jerry-seinfeld-js": () => import("/home/ops/andymoresi/src/pages/dear-jerry-seinfeld.js" /* webpackChunkName: "component---src-pages-dear-jerry-seinfeld-js" */),
  "component---src-pages-index-js": () => import("/home/ops/andymoresi/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legally-blonde-js": () => import("/home/ops/andymoresi/src/pages/legally-blonde.js" /* webpackChunkName: "component---src-pages-legally-blonde-js" */),
  "component---src-pages-matilda-js": () => import("/home/ops/andymoresi/src/pages/matilda.js" /* webpackChunkName: "component---src-pages-matilda-js" */),
  "component---src-pages-music-made-to-order-js": () => import("/home/ops/andymoresi/src/pages/music-made-to-order.js" /* webpackChunkName: "component---src-pages-music-made-to-order-js" */),
  "component---src-pages-school-of-rock-js": () => import("/home/ops/andymoresi/src/pages/school-of-rock.js" /* webpackChunkName: "component---src-pages-school-of-rock-js" */),
  "component---src-pages-skype-js": () => import("/home/ops/andymoresi/src/pages/skype.js" /* webpackChunkName: "component---src-pages-skype-js" */),
  "component---src-pages-we-will-rock-you-js": () => import("/home/ops/andymoresi/src/pages/we-will-rock-you.js" /* webpackChunkName: "component---src-pages-we-will-rock-you-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/ops/andymoresi/.cache/data.json")

